import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './i18n'
import { Provider } from 'react-redux'
import { store } from './app/store'
import { applyCssVariables } from './styles/colors'
import { Spin } from 'antd'

applyCssVariables()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    //? there is an error occured "A component suspended while responding to synchronous input. This will cause the UI to be replaced with a loading indicator. To fix, updates that suspend should be wrapped with startTransition." thats why this suspend method added
    <Suspense
        fallback={
            <Spin
                size="large"
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translateX(-50%) and translateY(-50%)',
                }}
            />
        }
    >
        <Provider store={store}>
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </Provider>
    </Suspense>
)

reportWebVitals()
