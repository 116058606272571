export const avatarNamesMap: { [x: string]: string } = {
    A: '#154c9b',
    B: '#3b7ad4',
    C: '#70a6f3',
    D: '#7781ca',
    E: '#9877ca',
    F: '#c178c9',
    G: '#ee87c5',
    H: '#ca7881',
    I: '#75c9c0',
    J: '#75c997',
    K: '#80ca79',
    L: '#aacb78',
    M: '#cbbc78',
    N: '#cb9878',
    O: '#bb774c',
    P: '#905b39',
    Q: '#903737',
    R: '#bf4949',
    S: '#f37070',
    T: '#ff9c3c',
    U: '#fbc84c',
    V: '#cbc8a1',
    W: '#a9a9a9',
    X: '#767676',
    Y: '#cb9878',
    Z: '#903737',
    '+': '#9e9e9e',
}
